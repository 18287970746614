import React from 'react';

interface AppStoreButtonProps {
  appUrl: string;
}

const AppStoreButton: React.FC<AppStoreButtonProps> = ({ appUrl }) => {
  return (
    <div style={styles.fullHeight}>
      <a
        href={appUrl}
        target='_blank'
        rel='noopener noreferrer'
        style={styles.link}
      >
        <button style={styles.button}>
        <p className="material-icons" style={styles.icon}>iOS</p>
        <span style={styles.text}>Download on the App Store</span>
        </button>
      </a>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  fullHeight: {
    margin: 'auto',
    textAlign: 'center',
    padding: '22px 0',
  },
  link: {
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
  },
  button: {
    alignItems: 'center',
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#3DDC84', // App Store green
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  icon: {
    fontSize: '24px',
    marginRight: '10px',
  },
  text: {
    fontSize: '16px',
  },
};

export default AppStoreButton;
