export const AndroidApps = {
  student: 'https://play.google.com/store/apps/details?id=com.mindjjo.mindjjo',
  ssvm: 'https://play.google.com/store/apps/details?id=com.mindjjo.ssvm',
  reeds: 'https://play.google.com/store/apps/details?id=com.mindjjo.reeds',
  ssvmkidscasa: 'https://play.google.com/store/apps/details?id=com.mindjjo.ssvmkidscasa',
  'staging-student': 'https://play.google.com/store/apps/details?id=com.mindjjo.mindjjo',
}

export const IOSApps = {
  student: 'https://apps.apple.com/pl/app/mindjjo/id1584350478',
  ssvm: 'https://apps.apple.com/us/app/ssvm-mindjjo/id6450660403',
  reeds: 'https://apps.apple.com/us/app/reeds-mindjjo/id6450659313',
  ssvmkidscasa: 'https://apps.apple.com/app/ssvm-kidscasa-mindjjo/id6503405296',
  'staging-student': 'https://apps.apple.com/pl/app/mindjjo/id1584350478',
}
