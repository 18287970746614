import axios from 'axios';
import { store } from "../store";
import { REQUEST_LOGOUT, UNAUTHENTICATED } from "../store/definitions/authConstants";
import * as ApiRoutes from '../routes/routeConstants/apiRoutes';
import { deserialize } from 'serializr';
import { LocalStorage } from '../shared/utils/LocalStorageHelpers';
import { Student } from '../models/student.model';
import { LOGIN } from '../routes/routeConstants/appRoutes';
import Notification from '../shared/components/Notification';
import { NotificationTypes } from '../enums/notificationTypes';

export const getHeaders = (): any => {
    let headers;
    const accessToken = LocalStorage.getItem("ACCESS_TOKEN");
    headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken ? accessToken : ""}`,
    };
    return headers;
};

const axiosInstanceV2 = axios.create({
    baseURL: ApiRoutes.BASE_URL_V2,
    timeout: 20000,
});

axiosInstanceV2.interceptors.request.use(function (config) {
    config.headers = getHeaders();
    return config;
});

axiosInstanceV2.interceptors.response.use(
    (response): any => {
        return {
            data: response.data,
            message: response.statusText,
            status: response.status
        }
    },
    (error) => {    
        const { response } = error;
        if (response?.status === 401) {
            const originalRequest = error?.config;
            originalRequest._retry = true;
            const refreshToken = LocalStorage?.getItem("REFRESH_TOKEN");
            const payload = {
                student: {
                    grant_type: "refresh_token",
                    refresh_token: refreshToken,
                },
            };
            return axios
                .post(`${ApiRoutes.BASE_URL}${ApiRoutes.PARENT_LOGIN}`, payload)
                .then((response) => {
                    if (response.status >= 200 && response.status <= 299) {
                        const userDetails = deserialize(
                            Student,
                            response.data["user"]
                        );
                        const { token } = response.data;
                        LocalStorage.setItem(
                            "ACCESS_TOKEN",
                            token.access_token
                        );
                        LocalStorage.setItem(
                            "REFRESH_TOKEN",
                            token.refresh_token
                        );
                        axios.defaults.headers.common["Authorization"] =
                            "bearer " + token.access_token;
                        originalRequest.headers["Authorization"] =
                            "bearer " + token.access_token;
                        return axios(originalRequest);
                    }
                })
                .catch((error) => {
                    localStorage.clear();
                    window.location.replace(LOGIN)
                    store.dispatch({
                        type: UNAUTHENTICATED,
                        payload: "Request for logout",
                    });
                });
        }
        if(response?.status === 401) {
            store.dispatch({
                type: UNAUTHENTICATED,
                payload: "Request for logout",
            });
        }
        if(response?.status == 422) {
            Notification({
                message: response?.data?.error,
                description: "",
                type: NotificationTypes.ERROR,
              });
        }
        return Promise.reject(error);
    }
);

export default axiosInstanceV2;
