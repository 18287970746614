import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { LOGIN } from "../../routes/routeConstants/appRoutes";
import UIButton from "../../shared/components/UIButton";
import "./main.scss";
import { useHistory, Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";

let LoadImage = ({ appName, ...rest }) => {
  try {
    return <img src={require(`../../assets/${appName}/app-icon-white.svg`)}  {...rest} />;
  }
  catch (err) {
    return <img src={require('../../assets/images/app-icon-white.svg')} {...rest} />;
  }
}

let LoadSignUpImage = ({ appName, ...rest }) => {
  try {
    return <img src={require(`../../assets/${appName}/signup.svg`)}  {...rest} />;
  }
  catch (err) {
    return <img src={require('../../assets/images/signup.svg')} {...rest} />;
  }
}

const Main = ({ }) => {
  let history = useHistory()
  let hostName = window.location.host.split('.')[0].toLocaleLowerCase()
  sessionStorage.setItem("APP_NAME", hostName);

    const isMain = window.location.href.indexOf("/main") > -1

    if (!isMain && isMobile) {
      return (
      <Redirect to={LOGIN} />
      )
    }

  const handleLogin = (loginAs: string) => {
    if (loginAs === 'student')
      history.push(LOGIN);
    else {
      let baseURL = 'tutor.mindjjo.com'
      if (hostName === 'student' || hostName.includes('localhost'))
        window.open('https://' + baseURL, "_self")
      else if(hostName === 'staging-student'){
        window.open('https://staging-tutor.mindjjo.com',"_self")
      }
      else
        window.open('https://' + hostName + baseURL, "_self")
    }
  };

  if (hostName === 'student') {
    return (
      <Redirect to={LOGIN} />
    )
  }

  return (
    <div className={`main__container ${hostName}`}>
      <Row className="main-container__wrapper">
        <Col xs={24} sm={24} md={14}>
          <div className="image__wrapper">
            <LoadSignUpImage appName={hostName} />
          </div>

        </Col>
        <Col xs={24} sm={24} md={8}>
          <div className="form__wrapper">
            {hostName !== null && <LoadImage className="mindjjo__logo" appName={hostName} />}
            <div className="login-form__container">
              <h2>Please select an appropriate login type</h2>

              <div className="btn__wrapper">
                <UIButton clickHandler={() => handleLogin('student')} type="submit" className="mt-3" loading={false}>
                  Student
                </UIButton>
              </div>
              <div className="btn__wrapper">
                <UIButton clickHandler={() => handleLogin('tutor')} type="submit" className="mt-3" loading={false}     >
                  Tutor
                </UIButton>
              </div>

            </div>
          </div>
        </Col>
      </Row>

    </div>
  );
};

export default Main;
