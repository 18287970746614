import { generatePath } from "react-router-dom";
import { deserialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import axiosInstanceV2 from "../../interceptor/axiosInstanceV2";
import { Chapters } from "../../models/Chapters/chapter.model";
import { Subjects } from "../../models/Subject/subject.model";
import {
  STUDENT_COURSES,
  SUBJECTS,
  CHAPTERS,
  CHAPTER_CONTENTS,
} from "../../routes/routeConstants/apiRoutes";
import { Course } from "../../models/student.model";
import { SubjectModel } from "../../models/Exam/SubjectModel/subject.model";

export class LearnService {
  static getStudentCourses(
    onSuccess: (data: any) => void,
    onError: () => void
  ) {
    return axiosInstance
      .get(STUDENT_COURSES)
      .then((response) => {
        try {
          const data = deserialize(Course, response.data["student_courses"]);
          onSuccess(data);
        } catch (error) {
          //console.log(error.message);
        }
      })
      .catch((error) => {});
  }

  static getSubjects(
    params: any,
    courseId: any,
    onSuccess: (data: any) => void
  ) {
    return axiosInstance
      .get(generatePath(SUBJECTS, { courseId }), { params: params })
      .then((response) => {
        const data = deserialize(
          SubjectModel,
          response.data["class_room_subjects"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        //console.log(error.message);
      });
  }

  static getChapters(
      queryParams: any,
    {
      ...params
    }: { courseId: number | string | undefined; subjectId: number | string },
    onSuccess: (data: any) => void
  ) {
    return axiosInstance
      .get(generatePath(CHAPTERS, { ...params }), {
          params: queryParams
      })
      .then((response) => {
        const { chapters } = deserialize(Chapters, response?.data);
        onSuccess(chapters);
      })
      .catch((error) => {
        //console.log(error.message);
      });
  }

  static getChaptersV2(
    queryParams: any,
    {
      ...params
    }: { courseId: number | string | undefined; subjectId: number | string },
    onSuccess: (data: any) => void
  ) {
    return axiosInstanceV2
      .get(generatePath(CHAPTERS, { ...params }), {
        params: queryParams,
      })
      .then((response) => {
        const { chapters } = deserialize(Chapters, response?.data);
        onSuccess(chapters);
      })
      .catch((error) => {
        //console.log(error.message);
      });
  }

  static getChapterContent(chapterId: string | number) {
    // //console.log(generatePath(CHAPTER_CONTENTS));
    // return axiosInstance.get(generatePath(CHAPTER_CONTENTS, {
    //     chapterId
    // })).then(response => {
    //     //console.log(response.data);
    // }).catch(error => {
    //     //console.log(error.message);
    // });
  }
}
