import React, {useEffect, useState } from "react";
import LoginForm from "./LoginForm";
import { Col, Row } from "antd";
import "./login.scss";
import { useHistory } from "react-router-dom";
import { LEARN } from "../../../routes/routeConstants/appRoutes";
import VerifyOtpImage from "../../../assets/images/verify-otp.svg";
import PasswordUpdateLink from "./PasswordUpdateLink";

import AuthContainer from "../../../store/container/AuthContainer";


let LoadImage = ({ appName, ...rest }) => {
  try {
    return <img src={require(`../../../assets/${appName}/app-icon-white.svg`)}  {...rest} />;
  }
  catch (err) {
    return <img src={require('../../../assets/images/app-icon-white.svg')} {...rest} />;
  }
}

let LoadSignUpImage = ({ appName, ...rest }) => {
  try {
    return <img src={require(`../../../assets/${appName}/signup.svg`)}  {...rest} />;
  }
  catch (err) {
    return <img src={require('../../../assets/images/signup.svg')} {...rest} />;
  }
}

const Login = ({ app_name, setAppName }) => {
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const history = useHistory();

  useEffect(() => {
    let hostName = window.location.host.split('.')[0].toLocaleLowerCase()
    sessionStorage.setItem("APP_NAME", hostName);
    setAppName(hostName)
  }, [])

  const handleLogin = (email: string, type: "success" | "update") => {
    setEmail(email);
    if (type == "update") setStep((step) => step + 1);
    else history.push(LEARN);
  };

  return (
    <Row className="auth-container__wrapper">
      <Col xs={2} lg={14}>
        <div className="image__wrapper">
          {
            !step ? <LoadSignUpImage  appName={app_name}/>:<img src={VerifyOtpImage} />
          }
        </div>

      </Col>
      <Col xs={20} lg={8}>
        <div className={`form__wrapper ${app_name}`}>
          {app_name !== null && <LoadImage className="mindjjo__logo" appName={app_name} />}
          <div className="login__container">
            <div>
              {step ? (
                // <OTPForm phoneNumber={mobileNumber} handleStep={handleStep} />
                <PasswordUpdateLink
                  email={email}
                  loginHandler={() => {
                    setStep(0);
                    setEmail("");
                  }}
                />
              ) : (
                <LoginForm successHandler={handleLogin} />
              )}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AuthContainer(Login);
