import React, { FC, useEffect, useRef, useState } from "react";
import "video-react/dist/video-react.css";

import { LocalStorage } from '../../../../../shared/utils/LocalStorageHelpers';
import "./videoPlayer.scss";
import ReactPlayer from "react-player";
import AuthContainer from "../../../../../store/container/AuthContainer";
import { Course, Student } from "../../../../../models/student.model";
import AppIcon from "../../../../../assets/images/app-icon-white.svg"
import { NET_BASE_URL } from '../../../../../routes/routeConstants/apiRoutes';
import { ContentHistoryService } from '../../../../../services/ContentHistoryService/contentHistory.service';
import { ContehtHistoryModel } from '../../../../../models/ContentHistory/contentHistory';
interface VideoPlayerProps {
  fileUrl: string;
  user: Student;
  authenticated: boolean
  contentLibraryId?:number
  currentCourse: Course
  updateCompletion: any;
}

const VideoPlayer: FC<VideoPlayerProps> = ({ fileUrl, user, authenticated,contentLibraryId, currentCourse, updateCompletion }) => {
  const playerRef = useRef<any>({});
  const [isReady, setIsReady] = useState<boolean>(false);
  const [completionRequestSent, setCompletionRequestSent] = useState<boolean>(false);
  const [recentContent, setRecentContent] = useState<any>({});
  const [documentHeight, setDocumentHeight] = useState<any>("100%")
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)

  const onReady = () => {
    setIsReady(true);
  };

  const handleResize = () => {
    if (isFullScreen) {
      setDocumentHeight(`${window.innerHeight}px`)
    } else {
      setDocumentHeight(`100%`)
    }
  };

  window.addEventListener('resize', handleResize);

  function toggleFullscreen() {
    if (!document.fullscreenElement) {
      document.getElementById("vid-cont")?.requestFullscreen();
      setIsFullScreen(true)
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setIsFullScreen(false)
    }
  }
  function handleAESencryption(xhr, url) {
    if (url.includes("mindjjo.com")) {
      url = `${NET_BASE_URL}/Content/GetKeyById?contentLibraryId=${contentLibraryId}`
      xhr.open('GET', url, true);
    }
    if (url.includes("GetKey")) {
      const accessToken = LocalStorage.getItem("ACCESS_TOKEN");
      xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken)
    }
  }

  const updateLastPlayed = (payload: any) => {
      setRecentContent({
      ...recentContent,
      playedSeconds: payload.playedSeconds,
    });
    LocalStorage.setItem('RECENT_WATCHED_CONTENT', {
      ...recentContent,
      playedSeconds: payload.playedSeconds,
    });

    if ((playerRef.current.getDuration() - payload.playedSeconds < 6) && !completionRequestSent) {
      setCompletionRequestSent(true);

      updateCompletion();
    }
  };

  useEffect(() => {
    if (contentLibraryId) {
      ContentHistoryService.createRecentlyWatchedHistory(
        {courseId: currentCourse.id},
        { content_library_id: contentLibraryId },
        (contentHistory: ContehtHistoryModel) => {

            setRecentContent(contentHistory);
            const timeToStart = contentHistory?.playedSeconds || 0;
            playerRef.current.seekTo(timeToStart, 'seconds');
        },
        () => {}
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentLibraryId, isReady]);

  useEffect(() => {
    return () => {
      let history = LocalStorage.getItem('RECENT_WATCHED_CONTENT');

      if (history?.id) {
        ContentHistoryService.updateContentHistory(
          history,
          { id: history.id, courseId: currentCourse?.id},
          () => {
            localStorage.removeItem('RECENT_WATCHED_CONTENT');
          },
          () => {}
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentContent])


  return (
    <div className="video-container" id="vid-cont">
      <div className='react-player'>
        <ReactPlayer
          className='player-wrapper'
          controls={true}
          url={fileUrl}
          width="100%"
          height={documentHeight}
          onContextMenu={e => e.preventDefault()}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload nofullscreen",
                disablepictureinpicture: "true",
              },
              hlsOptions: {
                forceHLS: true,
                debug: false,
                xhrSetup: (xhr, url) => handleAESencryption(xhr, url)
              },
            },
          }}
          ref={playerRef}
          onReady={onReady}
          onProgress={(payload) => updateLastPlayed(payload)}
        />
      </div>
      <div className="marquee">
        <div>
          <p>{user?.firstName}</p>
          <p>STD{user?.id}</p>
        </div>

      </div>
      <div className="watermark">
        <img src={AppIcon} alt='' />

      </div>

      <button className="fullscreen-button" onClick={() => toggleFullscreen()}>
        Toggle Full Screen View
      </button>
    </div>
  );
};

export default AuthContainer(VideoPlayer);
