import React from 'react';

interface PlayStoreButtonProps {
  appUrl: string;
}

const PlayStoreButton: React.FC<PlayStoreButtonProps> = ({ appUrl }) => {
  return (
    <div style={styles.fullHeight}>
      <a
        href={appUrl}
        target='_blank'
        rel='noopener noreferrer'
        style={styles.link}
      >
        <button style={styles.button}>
          <p className='material-icons' style={styles.icon}>
            Android App
          </p>
          <span style={styles.text}>Download on Google Play</span>
        </button>
      </a>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  fullHeight: {
    margin: 'auto',
    textAlign: 'center',
    padding: '22px 0',
  },
  link: {
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
  },
  button: {
    alignItems: 'center',
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#3DDC84', // Play Store green
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  icon: {
    fontSize: '24px',
    marginRight: '10px',
  },
  text: {
    fontSize: '16px',
  },
};

export default PlayStoreButton;
