import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import RestrictAccess from "../RestrictedAccess";
import AuthContainer from "../../../store/container/AuthContainer";
import Login from "../../../views/Main";
import MobileScreen from "../MobileScreen/MobileScreen";
import { isMobile } from "react-device-detect";

const requireAuth = (Component: any, allowedRoles: any = []) => {
  const Authentication = (props: any) => {

    const isResetPassword = window.location.href.indexOf("reset-password") > -1

    useEffect(() => {
      const { authenticated, history } = props;

      if (isMobile && !isResetPassword) {
        return <MobileScreen />;
      } else if (
        !authenticated &&
        history.location.pathname !== appRoutes.MAIN
      ) {
        return history.push(appRoutes.MAIN);
      }
    }, [props, isResetPassword]);

    if (allowedRoles.length) {
      const { user } = props;
      return allowedRoles.includes(user.role) ? (
        <Component {...props} />
      ) : (
        <RestrictAccess />
      );
    }
    return props?.authenticated ? (
      <Component {...props} />
    ) : (
      <Login {...props} />
    );
  };
  return withRouter(AuthContainer(Authentication));
};

export default requireAuth;
