import React, { FC, useEffect, Fragment, useState } from "react";

import SubscriptionPopup from "../../../../shared/components/SubscriptionPopup";
import VideoCard from "../../../../shared/components/VideoCard";


import TitleContainer from "../../../../store/container/TitleContainer";
import { ITitleState } from "../../../../store/reducers/titleReducer";
import { LearnService } from "../../../../services/LearnService/learn.service";
import { useLocation, useParams } from "react-router-dom";
import { LocalStorage } from "../../../../shared/utils/LocalStorageHelpers";
import { Chapter as ChapterModel } from "../../../../models/Chapters/chapter.model";
import Chapter from "./Chapter";
import "./listChapters.scss";
import { ExamService } from "../../../../services/ExamService/exam.service";
import AuthContainer from "../../../../store/container/AuthContainer";
import { Course } from "../../../../models/student.model";
import { Spin } from "antd";


interface ListChaptersProps extends ITitleState {
  setTitle: Function;
  type: any;
  currentCourse: Course;
  isTrialCourse: boolean,
  isCourseExpired: boolean,
  currentProduct: any
}
const ListChapters: FC<ListChaptersProps> = ({
  setTitle,
  type,
  currentCourse,
  isTrialCourse,
  onSearch,
  isCourseExpired,
  currentProduct
}) => {
  const params: any = useParams();
  const { state }: { state: any } = useLocation();
  const [chapters, setChapters] = useState<ChapterModel[]>();
  const [infoPopup, setInfoPopup] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchChapters = (queryParams: any) => {
    type == "exam"
      ? ExamService.getExamSubjectChapters(
        queryParams,
        {
          subjectId: params?.subjectId,
          courseId: currentCourse?.id,
        },
        (chapters: ChapterModel[]) => {
          let newChapters = chapters.filter((data) => data.chapterContents?.length)
          if (currentProduct?.studenttype === 'institution')
            newChapters = [...newChapters].filter(data => data?.isactive === true).map(data => {
              data.chapterContents = data.chapterContents?.filter(data => data?.isactive === true) || []
              return data
            })
          setChapters(newChapters);
          setIsLoading(false);
        },
        () => { setIsLoading(false); }
      )
      : LearnService.getChaptersV2(
        queryParams,
        {
          subjectId: params?.subjectId,
          courseId: currentCourse?.id,
        },
        (chapters: ChapterModel[]) => {
          // let newChapters = chapters.filter((data) => data.chapterContents?.length)
          
          // if (currentProduct?.studentType === 'institution') {
          //   if (currentProduct?.instituteId === 1) {
          //     newChapters = [...newChapters].filter(data => data?.isactive === true).map(data => {
          //       data.chapterContents = data.chapterContents?.filter(data => data?.isactive === true) || []
          //       return data
          //     })
          //   }

          // } else if (currentProduct?.studentType !== 'institution') {
          //   newChapters = newChapters?.map((data, chIndex) => {
          //     //data.disabled = isTrialCourse ? isCourseExpired ? true : chIndex === 0 ? false : true : false
          //     data.disabled = isCourseExpired ? true : isTrialCourse ?  chIndex === 0 ? false : true : false
          //     return data
          //   })
          // }

          setChapters(chapters);
          setIsLoading(false);
        }
      );
  };


  useEffect(() => {
    setTitle({
      title: state?.subjectName,
      showBack: true,
      subText: "",
      icon: null,
      searchText: "Search chapters",
    });
  }, []);

  useEffect(() => {
    fetchChapters({ search: onSearch });
  }, [onSearch]);



  return (
    <Fragment>
      {isLoading ? (
        <div className='loading__container'>
          <Spin size='large' tip='Loading...' />
        </div>
      ) : (<div>
        <SubscriptionPopup
          visiblity={infoPopup}
          closeHandler={() => setInfoPopup(false)}
        />

        <div className="list-chapter__container">
          {chapters?.map(({ name, disabled, id }, ind) => (
            <Chapter
              key={"chapter#" + id + "_" + ind + 1}
              disabled={disabled}
              id={id}
              chapterNo={ind + 1}
              title={name || ""}
              courseId={currentCourse?.id}
              subjectName={state?.subjectName}
              onSubscription={() => setInfoPopup(true)}
            />
          ))}
        </div>
      </div>)}
    </Fragment>
  );
};

export default AuthContainer(TitleContainer(ListChapters));
