import React, { useState } from "react";
import { Formik, Form } from "formik";
import AuthContainer from "../../../../store/container/AuthContainer";
import InputField from "../../../../shared/components/InputField";
import { loginValidation } from "../LoginValidation";
import AuthService from "../../../../services/AuthService/auth.service";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import UIButton from "../../../../shared/components/UIButton";
import { Col, Row } from "antd";
import MailIcon from "../../../../assets/icon/mail.svg";
import "./loginForm.scss";
import {
  FORGOT_PASSWORD,
  HOME,
  PRIVACY_POLICY,
  REGISTER,
  TAC,
  LOGIN_OTP
} from "../../../../routes/routeConstants/appRoutes";
import { LoginCredentials } from "../../../../models/user.model";



interface LoginProps extends RouteComponentProps {
  successHandler: (email: string, type: "update" | "success") => void;
  setProductDetails: (productDetails: any) => void;
  app_name?:string
}

const LoginForm = ({ successHandler, setProductDetails, history,app_name }: LoginProps) => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
  });
  const onSubmit = (values: LoginCredentials) => {
    setButtonLoading(true);
    AuthService.loginWithCredentials(
      values,
      (student) => {
        // if (student?.confirmedAt) {
          getProductDetails(values)
        // } else {
        //   successHandler(values?.email ?? "", "update")
        // }
      },
      (error) => { },
      () => { setButtonLoading(false) }
    );
  };


  const getProductDetails = (values: LoginCredentials) => {
    AuthService.getStudentProductDetails((response: any) => {
      setProductDetails(response)
      successHandler(values?.email ?? "", "success")
    })

  }
  return (
    <div className="login-form__container">
     {app_name === 'ssvm' && <div className="about__mindjjo">
        <h1>
          Mindjjo is an Ed-tech platform designed to give a new dimension to
          interactive e-learning
        </h1>
        <p>
          At Mindjjo, we're passionate about education and technology and we
          believe that the two can come together to create interactive and
          enriching e-learning experiences. We are devoted to bringing
          innovation to the education system for the schools. Driving
          exceptional learning outcomes for each of the students. Preparing
          every school to impart international standard education. Empowering
          unparalleled exposure to opportunities through exclusive platforms.
        </p>
      </div> }
      <h2>Login to continue</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={loginValidation}
      >
        {({ values, isValid }) => (
          <Form>
            <Row>
              <Col span={24}>
                <InputField
                  type="email"
                  name="email"
                  placeholder="Mail Id"
                  className="mt-3"
                  suffix={<img src={MailIcon} />}
                />
              </Col>
              <Col span={24}>
                <InputField
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="mt-3"
                />
              </Col>
              <Col span={24}>
                <div className="btn__wrapper">
                  <UIButton
                    type="submit"
                    className="mt-3"
                    icon={<ArrowRightOutlined />}
                    loading={buttonLoading}
                  >
                    Login
                  </UIButton>
                </div>
              </Col>

              {/* <Col span={24}>
                <p className="login-form__forgot-password">
                  OR
                </p>
              </Col> */}

              {/* <Col span={24}>
                <div className="">
                  <UIButton
                    type="button"
                    icon={<ArrowRightOutlined />}
                    clickHandler={() => history.push(LOGIN_OTP)}
                    secondary
                  >
                    Login with phone
                  </UIButton>
                </div>
              </Col> */}
            </Row>
            {['student', 'staging-student', undefined].includes(app_name) && <p className="dont-have-acc">
              Don't have an account?<span onClick={() => history.push(REGISTER)}> Create one</span>
            </p>}
            <p className="login-form__forgot-password">
              Forgot Password?{" "}
              <span onClick={() => history.push(FORGOT_PASSWORD)}>
                Click here
              </span>
            </p>
            <p className="terms-and-conditions">
              By logging in, you agree to our{" "}
              <span onClick={() => history.push(TAC)}>
                Terms and Conditions
              </span>{" "}
              and that you have read our{" "}
              <span onClick={() => history.push(PRIVACY_POLICY)}>
                Privacy Policy.
              </span>
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AuthContainer(withRouter(LoginForm));
