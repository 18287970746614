import React, { useEffect, useState } from "react";
import AuthRouter from "../authRouters";
import { isMobile } from 'react-device-detect';
import MobileScreen from "../../../shared/components/MobileScreen/MobileScreen";
import AuthContainer from '../../../store/container/AuthContainer';


const AuthWrapper = ({app_name}) => {
    const isResetPassword = window.location.href.indexOf("reset-password") > -1

    return (
        <>
            {(isResetPassword || !isMobile) &&  <AuthRouter /> }
            {(!isResetPassword && isMobile) &&  <MobileScreen /> }
        </>
    )
}

export default AuthContainer(AuthWrapper);
