import React, { useEffect } from 'react'
import "./mobileScreen.scss";
import { isAndroid, isIOS } from 'react-device-detect';
import AppStoreButton from '../AppStoreButton/AppStoreButton';
import PlayStoreButton from '../PlayStoreButton/PlayStoreButton';
import { AndroidApps, IOSApps } from '../../utils/selectApp';
import reeds from '../../../assets/reeds/app-icon-white.svg';
import ssvm from '../../../assets/ssvm/app-icon-white.svg'
import ssvmkidscasa from '../../../assets/ssvmkidscasa/app-icon-white.svg';
import student from '../../../assets/images/app-icon-white.svg'

function MobileScreen() {
  let app_name = window.location.host.split('.')[0].toLocaleLowerCase()

  const iconsUrl = {
    reeds: reeds,
    ssvm: ssvm,
    ssvmkidscasa: ssvmkidscasa,
    student: student
  }
  
  const playStoreUrl = AndroidApps[app_name];
  const appStoreUrl = IOSApps[app_name];

  useEffect(()=> {
    let url = undefined
    
    if (isAndroid) {
      url = playStoreUrl
    } else if (isIOS) {
      url = appStoreUrl
    }

    window.open(url, '_self')
  }, [])

  return (
    <div className="background-image-div">
       <img
          src={iconsUrl[app_name]}
          className=''
          alt=''
        />
      <h3 className="main-heading">Please Download Application</h3>
      {isAndroid && <PlayStoreButton appUrl={playStoreUrl} />}
      {isIOS && <AppStoreButton appUrl={appStoreUrl} />}
    </div>
  )
}

export default MobileScreen
